b, 
strong {
    font-weight:@fontWeightBold;
}
select option[disabled] {
    color: #aaa;
    font-style: italic;
    font-weight: 100;
}
a,
.open-dialog,
.open-dialog-alert,
.close-dialog-alert {
    cursor:pointer;
}

.text-justify{ text-align:justify; }
.text-right{ text-align:right; }
.text-left{ text-align:left; }
.text-center{ text-align:center; }

.float-left{ float:left; }
.float-right{ float:right; }

.block-html {
    img {
        &.fr-fil:not(.fr-tni), 
        &.fr-tag.fr-fil:not(.fr-tni) {
            float: left;
        }
        &.fr-fil, 
        &.fr-tag.fr-fil {
            z-index: 1;
            position: relative;
            vertical-align: top;
            display: block;
            margin: auto;
            overflow: auto;
            padding: 10px 10px 10px 3px;
        }
        &.fr-fir, 
        &.fr-tag.fr-fir {
            z-index: 1;
            position: relative;
            vertical-align: top;
            display: block;
            margin: auto;
            overflow: auto;
            padding: 10px 3px 10px 10px;
        }
        &.fr-fir:not(.fr-tni), 
        &.fr-tag.fr-fir:not(.fr-tni) {
            float: right;
        } 
    } 
}

@media (min-width: 0px) AND (max-width: 576px) {
    .col-xs-0 {
        display:none !important;
    }
}
@media (min-width: 576px) AND (max-width: 768px) {
    .col-sm-0 {
        display:none !important;
    }
}
@media (min-width: 768px) AND (max-width: 992px) {
    .col-md-0 {
        display:none !important;
    }
}
@media (min-width: 992px) AND (max-width: 1200px) {
    .col-lg-0 {
        display:none !important;
    }
}
@media (min-width: 1200px) {
    .col-xl-0 {
        display:none !important;
    }
}
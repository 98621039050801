html,body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}    
body,p,blockquote,a {
    font-family: @fontFamily;
    -webkit-font-smoothing: antialiased;
}
a {
    text-decoration: none;
    &:focus,
    &:hover {
        text-decoration: none;
    }
}
.hide {
    display: none;   
}
.block-editor {
    >.contents {
        border: 1px dashed rgba(145, 145, 145, 0);
        margin: -1px;
        .transition() ;
    }
    >.contents-button {
        height: 30px;
        >.button-save {
            float: right;
            margin-top: -35px;
            margin-right: -1px;
            opacity: 0;
            .transition() ;
            &:hover,
            &:focus,
            &:active {
                margin-top: 0px;
                opacity: 1;
            }
        }
    }
    &:hover,
    &:focus,
    &.active {
        >.contents {
            border: 1px dashed rgba(145, 145, 145, 0.5);
            margin: -1px; 
        }
        >.contents-button {
            >.button-save {
                margin-top: 0px;
                opacity: 1;
            }
        }
    }
}
.block-html {
	>.froala_box {
		>.froala_element {
			min-height: initial !important;	
		}
	}
}

.main {
    opacity: 0%; 
    .transition();
	>.dialog-alert {
		position: fixed;
    	z-index: 1001;
    	top: 0;
    	left: -260px;
    	right: 0;
    	bottom: 0;
    	background-color: rgba(0, 0, 0, 0.8);
		opacity: 0;
	    width: 0;
		.transition();
		>.dialog-alert-content {
		    position: relative;
		    top: 50%;
		    transform: translateY(-50%);
		    color: #383d41;
		    background-color: #e2e3e5;
		    border-color: #d6d8db;
		    min-height: 150px;
		    min-width: 250px;
		    max-width: 500px;
		    margin: auto;
		    box-shadow: 0px 10px 10px 0px #000, inset 0px 0px 5px 4px #fff;
		    padding: 25px;
			>header {
				font-size: 20px;
				font-weight: bold;
				margin: 0 0 20px 0;
				padding: 0 0 10px 0;
				border-bottom: 2px solid #fff;
				text-align: center;
			}
			>section {
			    font-size: 16px;
			    margin: 0 0 20px 0;
			    text-align: center;
			}
			>footer {
				.btn {
					border-radius: 0;
					font-weight: bold;
				}
			}
		}
		&.dialog-alert-danger {
			>.dialog-alert-content {
			    color: #721c24;
			    background-color: #f8d7da;
			    border-color: #f5c6cb;	
			}
		}
		&.dialog-alert-success {
			>.dialog-alert-content {
			    color: #155724;
			    background-color: #d4edda;
			    border-color: #c3e6cb;
			}
		}
		&.dialog-alert-warning {
			>.dialog-alert-content {
				color: #856404;
				background-color: #fff3cd;
				border-color: #ffeeba;	
			}
		}
		&.dialog-alert-info {
			>.dialog-alert-content {
			    color: #004085;
			    background-color: #cce5ff;
			    border-color: #b8daff;
			}
		}
		&.open {
			left: 0;
			opacity: 1;
		    width: 100%;
		}
	}
    >.main-section {
        >.section-center {
            >.block-page {
                >.page-wrapper {
                    display: table;
                    >.wrapper-content {
                        float: left;
                        width: 62%;
                        padding-right: 30px;
                    }
                    >.wrapper-sidebar {
                        position: -webkit-sticky;
                        position: sticky;
                        top: 30px;
                        float: left;
                        width: 38%;
                    }
                }
            }
        }
    }
	>.main-footer {
		>.footer-section {
			>.section-footer {
				.block-copyright {
				    font-size: x-small;
				    font-weight: 600;
				    color: #000;
				    padding: 5px 0;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				    overflow: hidden;
				    a {
				        font-size: x-small;
				        font-weight: 600;
				        .transition();
    				    >img.logo-cms {
    				        width: 40px;
    				        float: left;
    				        margin-right: 10px;
    				        padding-right: 10px;
    				        border-right: 1px solid #000;
    			            .transition();
    			            &:hover {
    			                filter: invert(1);
    			            }
    				    }
				    }
				}
				.block-legal-obligations {
				    font-size: x-small;
				    font-weight: 600;
				    color: #000;
				    padding: 5px 0;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				    overflow: hidden;
				    text-align: right;
				    >span {
                        width: 30px;
                        float: right;
                        padding: 5px 5px 5px 5px;
                        background-color: #006395;
                        margin: 0 0 0 15px;
                        cursor: pointer;
                        .transition();
                        >img {
                            width: 100%;
                            filter: invert(1); 
                            .transition();
                        }
                        &:hover {
                            filter: invert(1);
                        }
				    }
				    a {
				        font-size: x-small;
				        font-weight: 600;
				        .transition();
				    }
				}
			}
		}
	}
	&.is-loaded {
        opacity: 100%; 
	}
}

.chat-online {
    position: fixed;
    z-index: 10000;
    bottom: 20px;
    right: 20px;
    >.co-btn-open {
        width: 80px;
        height: 80px;
        background-color: @colorPrimary;
        border-radius: 100%;
        line-height: 100px;
        text-align: center;
        box-shadow: 0px 1px 0px 1px @colorOnColorPrimary, 0px 1px 10px -2px @colorOnColorPrimary;
        cursor: pointer;
        >i {
            font-size: 3rem;
            color: @colorOnColorPrimary; 
        }
    }
    >form {
        >.card {
            box-shadow: 0 0 0 2px #a3a3a3, 0 -5px 20px -5px black;
            border: none;
            border-radius: 0;
            width: 400px;
            >.card-header {
                background-color: @colorPrimary;
                color: @colorOnColorPrimary; 
                padding: 0.5rem;
                border-radius: 0;
                >button.co-btn-close {
                    float: right;
                    border: none;
                    background-color: @colorPrimary;
                    border-radius: 0;
                    padding: 0 0.25rem;
                    .transition();
                    >i {
                        color: @colorOnColorPrimary; 
                        .transition();
                    }
                    &:hover {
                        background-color: #fff;
                        >i {
                            color: #000;
                        }
                    }
                }
                >span.icon-support {
                    float: left;
                    margin-right: 0.5rem;
                    >i {
                        
                    }
                }
            }
            >.card-body {
                height: 400px;
                overflow-x: hidden;
                overflow-y: scroll;
                padding: 0.5rem;
                background-color: #f0f0f0;
                >.message {
                    background-color: #fff;
                    border-radius: 1rem;
                    padding: 1rem;
                    max-width: 80%;
                    box-shadow: 1px 1px 4px -2px @colorPrimary;
                    >.caption {
                        >p {
                            >b {
                                color: @colorPrimary;
                            }
                            margin: 0;
                            font-size: .8rem;
                        }
                        >time {
                            font-size: 0.7rem;
                            text-transform: uppercase;
                            opacity: 75%;   
                        }
                    }
                    &.message-support {
                        margin: 0 0 1rem auto;
                    }
                    &.message-visitor {
                        margin: 0 auto 1rem 0;
                    }
                }
            }
            >.card-footer {
                padding: 0.5rem;
                border: none;
                background-color: #a3a3a3;
                border-radius: 0;
                >.input-group {
                    >.form-control {
                        font-size: 0.9rem;
                        border: none;
                        border-radius: 0;
                    }
                    >.input-group-append {
                        >button.btn {
                            background-color: @colorPrimary;
                            font-size: 0.9rem;
                            border: none;
                            border-radius: 0;
                            >i {
                                font-size: 0.9rem;
                                color: @colorOnColorPrimary; 
                            }
                        }
                    }
                }
            }
        }
    }
    &.is-closed {
        >form {
            display: none;
            pointer-events: none;
        }
        >.co-btn-open {
            display: block;
        }
    }
    &.is-opened {
        >form {
            display: block;
            pointer-events: inherit;
        }
        >.co-btn-open {
            display: none;
        }
    }
}

/* 
For Mobile 
Lorsque l'écran (fenêtre du navigateur) devient plus petit que 768 pixels :
*/
@media screen and (max-width: 768px) {
    .main {
        .main-section {
            .section-center {
                .block-page {
                    >.page-wrapper {
                        >.wrapper-content {
                            float: inherit !important;
                            width: 100% !important;
                            padding: 0 !important;
                        }
                        >.wrapper-sidebar {
                            float: inherit !important;
                            width: 100% !important;
                            padding: 0 !important;
                            position: inherit !important;
                            top: 0 !important;
                            margin-top: 1rem !important; 
                        }
                    }
                }
            }
        }
    }  
    .chat-online {
        bottom: 10px;
        right: 10px;
        >.co-btn-open {
            width: 40px;
            height: 40px;
            line-height: 50px;
            >i {
                font-size: 2rem;
            }
        }
        >form {
            margin-left: 10px;
            >.card {
                width: 100%;
            }
        }
    }
}
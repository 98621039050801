html,
body {
	background: #fafafa;
}
body, p, blockquote, a {
    .font-sizeMD();
    color: @colorSecondary;
}
h1, h2, h3, h4, h5, h6 {
   font-weight: @fontWeightBold; 
}
h1,.h1 {
    .font-sizeXL();
}
h2,.h2 {
    .font-sizeLG();
}
h3,.h3 {
    .font-sizeMD();
}
h4,.h4 {
    .font-sizeSM();
}
h5,.h5 {
    .font-sizeXS();
}
b, strong {
    font-weight: @fontWeightBold;
}
a {
    color: @colorPrimary;
    text-decoration: none;
    .transition();
    &:hover, 
    &:focus {
        color: #f7931e;
    }
}
.nav-pills > li {
    > a {
        &:hover, 
        &:focus {
            color: #fff;
            background-color: @colorPrimary;
        }
    }
    &.active {
        >a {
            color: #fff;
            background-color: @colorPrimary;
            pointer-events: none;
        }
    }
}
.list-group-item {
    &:hover, 
    &:focus {
        background-color: #f5f5f5;
    }
    &.active,
    &.active:hover, 
    &.active:focus {
        color: #fff;
        background-color: #f29322;
        border-color: #dddddd;
        pointer-events: none;
    }
}
.form-group,
.input-group {
    .input-group-prepend,
    .input-group-append {
        >.input-group-text {
            border-radius: 0;
            background-color: @colorPrimary;
            color: #fff;
            border: 1px solid @colorPrimary;
            >.fa {
                color: #fff;   
            }
        }
    }
    .form-control {
        color: @colorSecondary;
        background-color: #fff;
        background-image: none;
        border: 1px solid @colorPrimary;
        box-shadow: none;
        .transition();
        &:hover,
        &:focus {
            border-color: @colorPrimary;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
        }
        &:disabled {
            background-color: rgba(0, 0, 0, 0.5);
            pointer-events: none;
        }
    }
}
.form-control {
    border-radius: 0;
}
.btn {
    border-radius: 0;
    .transition();
    &.btn-default {
        background-color: #fff !important;
        border: 1px solid @colorPrimary !important;
        border-radius: 0 !important;
        .fa {
            color: @colorPrimary !important;
        }
        &:hover,
        &:focus {
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorPrimary;
        }
    }
    &.btn-primary {
        color: @colorSecondary !important;
        background-color: @colorPrimary !important;
        border: 1px solid @colorPrimary !important;
        border-radius: 0 !important;
        .fa {
            color: @colorSecondary !important;
        }
        &:hover,
        &:focus {
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorSecondary;
            -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorSecondary;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorSecondary;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorSecondary;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @colorSecondary;
        }   
    }
}
.card {
    border-radius: 0;
    >.card-header,
    >.card-footer {
        border-radius: 0;   
    }
}
.page-header {
    margin: 0 0 1rem 0;
    >h1,
    >h2,
    >h3 {
        color: @colorSecondary;
        max-width: max-content;
        box-shadow: inset 0px -2px 0px 0px #fff, inset 0px -4px 0px 0px @colorPrimary;
    }
}
.float-right, .pull-right {
    float: right;   
}
.float-left, .pull-left {
    float: left;   
}

main.main {
	background: #fafafa;
    margin-bottom: 0 !important;
    >.main-header {  
        >.header-section {
            >.section-header {
                background: @colorPrimary;
                >.container {
                    nav.navbar {
                        background: none;
                        border: none;
                        margin: 0;
                        height: 60px;
                        >a.navbar-brand {
                            height: 60px;
                            background: #fff;
                            padding: .5rem 1rem;
                            margin: -1rem 0;
                            >img {
                                height: 100%;
                            }
                        }
                        >button.navbar-toggler {
                            border-color: #fff;
                            background-color: #fff;
                            >span.navbar-toggler-icon {
                                background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
                            }
                        }
                        ul.navbar-nav {
                            >li.nav-item  {
                                .transition();
                                >a.nav-link {
                                    color: @colorSecondary;
                                    font-family: 'Oswald', sans-serif;
                                    font-size: large;
                                    font-weight: @fontWeightBold; 
                                	text-transform: uppercase;
                                    .transition() ;
                                    &:hover,
                                    &:focus {
                                        color: @colorSecondary;
                                        background: #fff;
                                    }
                                }
                                &.active {
                                    > a {
                                        color: @colorPrimary;
                                        background: #fff;
                                    }
                                }
                                &.dropdown {
                                    &.open {
                                        > a {
                                            color: @colorPrimary;
                                            background: #fff;
                                        } 
                                    }
                                }
                                &.menu-registration {
                                    >a {
                                        background-color: @colorSecondary;
                                        color: #fff;
                                        &:hover,
                                        &:focus {
                                            color: @colorSecondary;
                                            background: #fff;     
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
            }
            >.section-middle {
                background: #ddd;
                padding: 30px 0 15px 0;
                >.container {
                    #formResearchAdvert {
                        display: block;   
                    }
                    #formResearchAdvertiser {
                        display: none;   
                    }
                }
            }
            >.section-footer {
                background: #f7931e;
                >.container {
                    
                }
            }
        }
    }
    >.main-section {
        >.section-center {
            >.block-breadcrumb {
                background-color: #eee;
                margin-bottom: 1rem;
                ol.breadcrumb {
                    color: #555;
                    background-color: #eee;
                    border-radius: 0;
                    margin-bottom: 0px;
                    >li {
                        font-size: 12px;
                        text-transform: uppercase;
                        a {
                            font-size: 12px;
                            color: #555;   
                        }
                    }
                    > li + li:before {
                        content: "/\00a0";
                        padding: 0 5px;
                        color: #555;
                    }
                }
            }
            >.block-page {
			    margin-right: auto !important;
			    margin-left: auto !important;
            }
        }
    }
    >.main-footer {
        background: #ddd;
        margin-bottom: 0 !important;
        >.footer-section {
            >.section-middle {
            	padding: 75px 0;
                .page-header {
					padding: 0;
                    >.header-title {
						color: #111;
				    	width: 100%;
				    	box-shadow: 0 2px 0 0 @colorPrimary, inset 0 0px 0 0 @colorPrimary;
                    }
                }
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    >li {
                        background-color: @colorSecondary;
                        margin: 0 0 10px 0;
                        line-height: 40px;
                        >a {
                            color: #fff;
                            font-size: 12px;
                            display: block;
                            text-align: center;
                            text-transform: uppercase;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            .transition() ;
                            &:hover,
                            &:focus {
                                color: @colorPrimary;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            >.section-footer {
                background: @colorPrimary;
                .block-copyright,
                .block-legal-obligations {
                    font-size: 11px;
                    color: @colorSecondary;
                    padding: 5px 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    .logo-iminence {
                        width: 40px;
                        float: left;
                        margin-right: 10px;
                        padding-right: 10px;
                        border-right: 1px solid #ddd;
                    }
                    a {
                        font-size: 11px;
                        color: @colorSecondary;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    main.main {
        >.main-header {
            >.header-section {
            	>.section-header {
                    >.container {
                		>nav.navbar {
                	        height: inherit;
                            >.collapse {
                                margin-top: 1rem;
                                >ul.navbar-nav {
                                    >li.nav-item {
                                        >a.nav-link {
                                            padding: 0.25rem 1rem;
                                        }
                                    }
                                }
                            }
                        }
            	    }
            	}
        	}
        }
    }
}
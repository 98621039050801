.font-size(@sizeValue) {
    @remValue: @sizeValue;
    @pxValue: (@sizeValue * 10);
    font-size: ~"@{pxValue}px"; 
    font-size: ~"@{remValue}rem";
}
.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(linear,
							   left bottom,
							   left top,
							   color-stop(0, @start),
							   color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
								  @start,
								  @stop);
  background: -moz-linear-gradient(center bottom,
								   @start 0%,
								   @stop 100%);
  background: -o-linear-gradient(@stop,
								 @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@stop,@start));
}
.bw-gradient(@color: #F5F5F5, @start: 0, @stop: 255) {
  background: @color;
  background: -webkit-gradient(linear,
							   left bottom,
							   left top,
							   color-stop(0, rgb(@start,@start,@start)),
							   color-stop(1, rgb(@stop,@stop,@stop)));
  background: -ms-linear-gradient(bottom,
								  rgb(@start,@start,@start) 0%,
								  rgb(@stop,@stop,@stop) 100%);
  background: -moz-linear-gradient(center bottom,
								   rgb(@start,@start,@start) 0%,
								   rgb(@stop,@stop,@stop) 100%);
  background: -o-linear-gradient(rgb(@stop,@stop,@stop),
								 rgb(@start,@start,@start));
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",rgb(@stop,@stop,@stop),rgb(@start,@start,@start)));
}
.opacity(@opacity: 0.5) {
  -moz-opacity: @opacity;
  -khtml-opacity: @opacity;
  -webkit-opacity: @opacity;
  opacity: @opacity;
  @opperc: @opacity * 100;
  -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
  filter: ~"alpha(opacity=@{opperc})";
}
.transition(@duration:all 333ms, @ease:ease-out) {
  -webkit-transition: @duration @ease;
  -moz-transition: @duration @ease;
  -o-transition: @duration @ease;
  transition: @duration @ease;
}
.box-sizing(@sizing: border-box) {
  -ms-box-sizing: @sizing;
  -moz-box-sizing: @sizing;
  -webkit-box-sizing: @sizing;
  -o-box-sizing: @sizing;
  box-sizing: @sizing;
}
.text-shadow(@argument) {
    text-shadow: @argument;
}
.transition-delay(@argument) {
	-webkit-transition-delay: @argument; /* Safari */
	transition-delay: @argument;
}	